export const SET_PAGE_OF_JOBS = 'app/staffingWorkPage/SET_PAGE_OF_JOBS';
export const SET_BATCH_JOBS = 'app/staffingWorkPage/SET_BATCH_JOB_IDS';
export const SET_STAFFING_WORK_ROWS_PER_PAGE =
  'app/staffingWorkPage/SET_STAFFING_WORK_ROWS_PER_PAGE';
export const SET_ASSIGNMENTS_DETAILS_ROWS_PER_PAGE =
  'app/staffingWorkPage/SET_ASSIGNMENTS_DETAILS_ROWS_PER_PAGE';
export const SET_IS_SELECT_ALL_PAGES =
  'app/staffingWorkPage/SET_IS_SELECT_ALL_PAGES';
export const SET_IS_SELECT_ALL_PAGES_LOADING =
  'app/staffingWorkPage/SET_IS_SELECT_ALL_PAGES_LOADING';
export const SET_SELECTED_EDIT_FIELD =
  'app/staffingWorkPage/SET_SELECTED_EDIT_FIELD';
export const SET_SELECT_ALL_PAGES_COUNT =
  'app/staffingWorkPage/SET_SELECT_ALL_PAGES_COUNT';
export const SET_BATCH_ID = 'app/staffingWorkPage/SET_BATCH_ID';
export const SET_INCLUDES_AUTO_STAFFED_JOB =
  'app/staffingWorkPage/SET_INCLUDES_AUTO_STAFFED_JOB';
export const SET_INCLUDED_SOURCE_SYSTEMS =
  'app/staffingWorkPage/SET_INCLUDED_SOURCE_SYSTEMS';
export const SET_FOCUSED_JOB_ID = 'app/staffingWorkPage/SET_FOCUSED_JOB_ID';
