import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { Box, Button, Typography } from '@mui/material';
import { utcToZonedTime } from 'date-fns-tz';
import { useSelector } from 'react-redux';
import { isValid } from 'date-fns';
import { determineColor, determineIfTimeIsWithinRange } from '../utils';

function DateEdit({
  dateEdit,
  isLoading,
  selectedDate,
  setSelectedDate,
  errorDate,
  setTimeEdit,
  setErrorDate,
  setDateEdit,
  isOpen,
  isEditable,
}) {
  const batchJob = useSelector(
    (state) => state.staffingWorkPage.editJob.batchJobs
  )[0];
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const currentMode = Array.from(centralMode.values())[0];
  const isSingleModeOn = currentMode === 'single';
  const color = determineColor(isEditable);

  const handleDateChange = (event) => {
    const isTimeWithinRange = determineIfTimeIsWithinRange(
      event,
      utcToZonedTime(
        batchJob?.created_job_start_after,
        batchJob?.store_location_timezone
      ),
      utcToZonedTime(
        batchJob?.created_job_finish_before,
        batchJob?.store_location_timezone
      ),
      isOpen,
      isSingleModeOn
    );

    setSelectedDate({
      ...selectedDate,
      edit: isValid(event) ? event : null,
    });

    if (isSingleModeOn && !isTimeWithinRange) {
      setTimeEdit(true);
    }
  };

  if (dateEdit) {
    return (
      <DesktopDatePicker
        autoFocus
        label="Date"
        value={selectedDate.edit}
        disabled={isLoading}
        minDate={
          isSingleModeOn &&
          batchJob &&
          utcToZonedTime(
            batchJob.created_job_start_after,
            batchJob.store_location_timezone
          )
        }
        maxDate={
          isSingleModeOn &&
          batchJob &&
          utcToZonedTime(
            batchJob.created_job_finish_before,
            batchJob.store_location_timezone
          )
        }
        onChange={handleDateChange}
        onError={(value) => {
          if (isSingleModeOn) {
            setTimeEdit(true);
            setErrorDate(value);
          }
        }}
        css={{ width: '100%', marginTop: '24px' }}
        slotProps={{
          textField: {
            id: 'batch-edit-date-picker',
            helperText: errorDate
              ? 'Date is outside available range'
              : undefined,
          },
        }}
      />
    );
  }

  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '24px',
      }}
    >
      <Box>
        <Typography id="current-date-label" variant="subtitle3" css={color}>
          Date
        </Typography>
        <Typography id="current-date" variant="body1" css={color}>
          {selectedDate.view}
        </Typography>
      </Box>
      {isEditable && (
        <Button
          id="batch-edit-date"
          variant="text"
          onClick={() => setDateEdit(true)}
          disabled={isLoading}
        >
          Edit
        </Button>
      )}
    </Box>
  );
}

export default DateEdit;
