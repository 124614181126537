import React, { useEffect, useState } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import { BrightnessHigh, ExpandMore, Verified } from '@mui/icons-material';
import { getAssociateCertifications } from '../../../services/user';
import { ContentWrapper } from '../style';
import Error from './Error';
import { scrollIntoView } from '../../../lib/utils';
import LoadingSpinner from './LoadingSpinner';

function CertificationsCollapsableHeader({ associate }) {
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [certificationsError, setCertificationsError] = useState(null);
  const [certifications, setCertifications] = useState([]);
  const { palette } = useTheme();

  useEffect(() => {
    scrollIntoView(expanded, 'certification-accordion-details', 400);
  }, [expanded, certifications]);

  const getCertifications = () => {
    setIsLoading(true);
    getAssociateCertifications(associate.id)
      .then((results) => {
        setIsLoading(false);
        setCertifications(results);
      })
      .catch((error) => {
        setIsLoading(false);
        setCertificationsError(
          error.message || 'There was a problem retrieving the Associate record'
        );
      });
  };

  useEffect(() => {
    if (associate) {
      getCertifications();
    }
  }, [associate]);

  return (
    <Accordion
      expanded={expanded}
      square
      onChange={() => {
        setExpanded(!expanded);
      }}
      css={{ border: 0, boxShadow: 'unset', borderTop: '1px solid #c6cace' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore fontSize="medium" />}
        aria-controls="panel1b-content"
        id="panel1b-header"
      >
        <Typography id="certifications" variant="subtitle1">
          Certifications
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        id="certification-accordion-details"
        css={{ padding: '16px' }}
      >
        {isLoading && <LoadingSpinner id="associate-certifications-loading" />}
        {certificationsError && !isLoading ? (
          <Error
            id="associate-certifications-error"
            doRetry={() => {
              setCertificationsError(null);
              getCertifications();
            }}
          />
        ) : (
          <>
            {certifications.length === 0 && !isLoading ? (
              <Box id="no-certifications">
                <ContentWrapper>
                  <BrightnessHigh
                    fontSize="large"
                    css={{ marginBottom: '16px', color: '#479c6b' }}
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <Typography variant="subtitle1" css={{ textAlign: 'center' }}>
                    No Certifications
                  </Typography>
                </ContentWrapper>
                <ContentWrapper>
                  <Typography variant="body2" css={{ textAlign: 'center' }}>
                    This associate has no certifications.
                  </Typography>
                </ContentWrapper>
              </Box>
            ) : null}
            {certifications.map((certification, index) => (
              <Box
                key={certification.id}
                id={`certification-${index}`}
                css={{
                  borderRadius: '10px',
                  border: '1px solid #c6cace',
                  padding: '10px',
                  marginBottom: '8px',
                  backgroundColor: palette.backgroundColor.primary,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Verified fontSize="medium" color="success" />
                <Typography
                  variant="body2"
                  css={{
                    marginLeft: '16px',
                    textAlign: 'left',
                  }}
                >
                  {certification.name}{' '}
                </Typography>
              </Box>
            ))}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default CertificationsCollapsableHeader;
