import {
  Button,
  Collapse,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { primaryFont } from '../../../styles/theme';
import { EXCEPTIONS_TO_MATCH_DETAILS } from '../constants';
import { snakeToCamel, snakeToKebab } from '../../../services/case';
import { removeNonNumeric } from '../../../services/text';
import { setAdvancedSearch } from '../../../state/ducks/suggestAssociate/actions';
import { DEFAULT_ADVANCED_SEARCH } from '../../../state/ducks/suggestAssociate/constants';

const buildToggle = (label, id, checked, setChecked) => (
  <FormControlLabel
    id={`${snakeToKebab(id)}-switch`}
    key={`${id}_switch`}
    label={label}
    control={
      <Switch
        id={`${snakeToKebab(id)}-checkbox`}
        checked={checked}
        onChange={(event) => {
          setChecked(event.target.checked);
        }}
        size="small"
        css={{
          marginRight: '4px',
        }}
      />
    }
    css={{
      marginBottom: '8px',
      marginLeft: '0px',
    }}
  />
);

function AdvancedSearch({
  currentAdvancedSearchOptions,
  setCurrentAdvancedSearchOptions,
  lastAdvancedSearchOptions,
  isLoading,
  hasSearchChanged,
  setPage,
}) {
  const { milesRadius } = currentAdvancedSearchOptions;
  const hasCachedAdvancedSearchOptions = !isEqual(
    lastAdvancedSearchOptions,
    DEFAULT_ADVANCED_SEARCH
  );

  const milesRadiusError = {
    isErrored: milesRadius === '' || milesRadius > 99,
    message:
      milesRadius > 99
        ? 'Distance must be 99 miles or less'
        : 'Search radius number is required',
  };
  const [isExpanded, setIsExpanded] = useState(hasCachedAdvancedSearchOptions);
  const suggestAssociateJob = useSelector(
    ({ suggestAssociate: { job } }) => job
  );
  const dispatch = useDispatch();
  useEffect(() => {
    setIsExpanded(hasCachedAdvancedSearchOptions);
  }, [suggestAssociateJob.id]);

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <Typography
        id="advanced-search-toggle-button"
        variant="subtitle1"
        onClick={handleExpandToggle}
        css={{
          fontFamily: primaryFont,
          color: '#0090BA',
          marginTop: '8px',
          marginBottom: '8px',
          cursor: 'pointer',
          lineHeight: '18px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        Advanced Search
        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Typography>
      <Collapse in={isExpanded} id="advanced-search-form">
        <FormGroup>
          {Object.keys(EXCEPTIONS_TO_MATCH_DETAILS).map((exception) => {
            const exceptionCamelCase = snakeToCamel(exception);
            return buildToggle(
              EXCEPTIONS_TO_MATCH_DETAILS[exception].text,
              exception,
              currentAdvancedSearchOptions[exceptionCamelCase],
              (value) => {
                setCurrentAdvancedSearchOptions({
                  ...currentAdvancedSearchOptions,
                  [exceptionCamelCase]: value,
                });
              }
            );
          })}
          <TextField
            id="search-radius"
            label="Search radius (miles)"
            type="text"
            size="small"
            value={milesRadius}
            onChange={({ target: { value } }) => {
              setCurrentAdvancedSearchOptions({
                ...currentAdvancedSearchOptions,
                milesRadius: removeNonNumeric(value),
              });
            }}
            error={milesRadiusError.isErrored}
            helperText={
              milesRadiusError.isErrored ? milesRadiusError.message : ''
            }
            css={{
              marginTop: '16px',
            }}
          />
          <Button
            variant="contained"
            size="large"
            id="suggest-button"
            disabled={
              isLoading || hasSearchChanged || milesRadiusError.isErrored
            }
            onClick={() => {
              setPage(0);
              dispatch(setAdvancedSearch(currentAdvancedSearchOptions));
            }}
            css={{ marginTop: '16px' }}
          >
            Search
          </Button>
        </FormGroup>
      </Collapse>
    </>
  );
}

export default AdvancedSearch;
