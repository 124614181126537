import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function format(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const code = match[1] ? '+1 ' : '';
    return [code, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

function Jyver({ fullName, phone, title, divisionName, employeeId }) {
  const { palette } = useTheme();

  return (
    <>
      <Typography
        variant="body2"
        css={{
          fontWeight: '600',
          color: palette.secondary.main,
        }}
        className="jyver-full-name"
      >
        {fullName}
      </Typography>
      {employeeId && (
        <Typography
          variant="body2"
          className="jyver-employee-id"
          css={{ color: palette.secondary.main }}
        >
          {employeeId}
        </Typography>
      )}
      {phone && (
        <Typography
          variant="body2"
          data-testid="jyver-phone-number"
          css={{ color: palette.secondary.main }}
        >
          {format(phone)}
        </Typography>
      )}
      {title && (
        <Typography variant="body2" css={{ color: palette.secondary.main }}>
          {title}
        </Typography>
      )}
      {divisionName && (
        <Typography
          variant="body2"
          className="jyver-division-name"
          css={{ color: palette.secondary.main }}
        >
          {divisionName}
        </Typography>
      )}
    </>
  );
}

export default Jyver;
