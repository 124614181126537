import React from 'react';
import { convertMinsToHrsMins } from 'lib/utils';
import { Box } from '@mui/material';

export const determineDisabledText = ({
  isCanceled,
  isCompletedAssignment,
  isCompletedDateTime,
  isNotEditable,
}) => {
  if (isCanceled) {
    return 'Cancelled assignments can only be edited in the home system';
  }
  if (isCompletedAssignment) {
    return 'Team Member cannot be assigned, reassigned, or unassigned for work that is completed.';
  }
  if (isCompletedDateTime) {
    return 'Assignments in a Completed status cannot be edited';
  }
  if (isNotEditable) {
    return 'The Job Source System does not allow Datetime editing';
  }
  return '';
};

export const filterJobs = (jobs, jobIds) =>
  jobs.filter((job) => jobIds.includes(job.id));

export const removeDuplicates = (entities) =>
  entities.filter(
    (entity, index, array) =>
      array.findIndex((otherEntity) => otherEntity.id === entity.id) === index
  );

export const getBatchDialog = (pageCount, onSelectAllClick) => ({
  dialog: {
    text: (
      <>
        <Box>{`${pageCount} assignments have been selected`}</Box>
        <Box css={{ marginLeft: '12px' }}>-</Box>
      </>
    ),
  },
  button: {
    onClick: () => {
      onSelectAllClick();
    },
    text: 'SELECT ALL FILTERED',
  },
});

export const determineFooterCount = (
  batchJobs,
  isSelectAllPagesLoading,
  selectAllJobsCount
) => {
  if (isSelectAllPagesLoading) {
    return 'Counting selected rows';
  }
  if (selectAllJobsCount > 0) {
    return `${selectAllJobsCount} rows selected`;
  }
  if (batchJobs.length > 0) {
    return `${batchJobs.length} row${batchJobs.length > 1 ? 's' : ''} selected`;
  }
  return '';
};

export const updateSingleRow = (gridApiRef, updatedJob) =>
  gridApiRef.current.updateRows([
    {
      id: updatedJob.id,
      projectId: `${updatedJob.external_project_identifier} - ${updatedJob.name}`,
      assignmentId: updatedJob,
      associate: updatedJob,
      status_central: updatedJob,
      date: updatedJob,
      time: updatedJob,
      duration: convertMinsToHrsMins(updatedJob.estimated_time, false, true),
      location: updatedJob,
      storeLocationTimezone: updatedJob.store_location_timezone,
    },
  ]);

const fromHomeSystem = 'From Home System';

export const determineDeclineReasonText = (declineReasonText, declineReason) =>
  declineReason === fromHomeSystem
    ? declineReasonText
    : `${declineReason} ${declineReasonText ? `- ${declineReasonText}` : ''}`;
