import React from 'react';
import styled from '@emotion/styled/macro';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBatchJobs,
  setFocusedJobId,
  setIncludesAutoStaffedJob,
  setSelectedEditField,
} from 'state/ducks/staffingWorkPage/actions';
import { convertMinsToHrsMins } from '../../../lib/utils';
import { determineDisabledText } from '../utils';
import EditPencilOverlay from './EditPencilOverlay';

const ExpectedDuration = styled.div`
  display: inline;
  border-radius: 2px;
`;

function JobTimeDrawerEditing({ job }) {
  const status = job.status_central;
  const startDate = job.job_start_after;
  const endDate = job.job_finish_before;
  const eta = job.estimated_time;
  const storeTimezone = job.store_location_timezone;
  const advantageSourceSystemName = job.advantage_source_system_name;
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const isCompletedDateTime = status === 'Completed';
  const isCanceled = status === 'Canceled';
  const isNotEditable = advantageSourceSystemName !== 'JET';
  const isDisabled = isCanceled || isNotEditable || isCompletedDateTime;
  const startDateWithTimezone = utcToZonedTime(startDate, storeTimezone);
  const endDateWithTimezone = utcToZonedTime(endDate, storeTimezone);
  const timeZoneOptions = { timeZone: storeTimezone };
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const currentMode = Array.from(centralMode.values())[0];
  const isSingleMode = currentMode === 'single';
  const isEditDrawerEnabled =
    isSingleMode && !isDisabled && job.advantage_source_system_name === 'JET';
  const disabledText = determineDisabledText({
    isNotEditable,
    isCompletedDateTime,
    isCanceled,
  });

  const handleOpenBatchEdit = () => {
    if (isEditDrawerEnabled) {
      dispatch(setFocusedJobId(job.id));
      dispatch(setSelectedEditField('time'));
      dispatch(setBatchJobs([job]));
      dispatch(
        setIncludesAutoStaffedJob(
          job.status_attributes.includes('auto_staffed')
        )
      );
    }
  };

  const timeOut = (
    <>
      {format(startDateWithTimezone, 'h:mm a', timeZoneOptions)}
      {' - '}
      {format(endDateWithTimezone, 'h:mm a', timeZoneOptions)}{' '}
      <span>
        (
        {format(utcToZonedTime(startDate, storeTimezone), 'z', timeZoneOptions)}
        )
      </span>
    </>
  );

  return (
    <Tooltip title={disabledText}>
      <Box
        onClick={handleOpenBatchEdit}
        className="job-time-wrapper"
        css={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          ...(isSingleMode && !disabledText
            ? {
                '&:hover > div': {
                  display: 'flex',
                },
              }
            : {}),
        }}
      >
        <Box>
          <Typography
            variant="body2"
            css={{
              fontWeight: '600',
              color: palette.secondary.main,
            }}
          >
            {timeOut}
          </Typography>
          {eta && (
            <ExpectedDuration title={convertMinsToHrsMins(eta)}>
              <Typography
                variant="body2"
                css={{ color: palette.secondary.main }}
              >
                {convertMinsToHrsMins(eta, false, true)}
              </Typography>
            </ExpectedDuration>
          )}
        </Box>
        <EditPencilOverlay id="time-edit" />
      </Box>
    </Tooltip>
  );
}

export default JobTimeDrawerEditing;
