export const selected = '#EBF6F9';
export const hovered = '#F5FBFC';
export const selectedRow = '#EBF6FA';
export const hoveredCount = '#B3DEEA';
export const boostRetail = '#0B3B60';
export const watermelon = '#e54360';
export const secondaryColor = '#0090BA';
export const errorFontColor = '#E44D34';
export const offwhite = '#f8f8f8';
export const primaryText = '#061a2ade';
export const secondaryText = '#757E87';
export const completedColor = '#2DA280';
export const claimedColor = '#5B4C66';
export const pastDueColor = '#E3903D';
export const urgentColor = '#072943';
export const inProgressColor = '#3394C5';
export const statusesMap = {
  completed: completedColor,
  complete: completedColor,
  in_progress: inProgressColor,
  active: secondaryText,
  claimed: claimedColor,
  staffed: claimedColor,
  unclaimed: errorFontColor,
  unstaffed: errorFontColor,
  expired: primaryText,
  past_due: pastDueColor,
  canceled: errorFontColor,
  urgent: urgentColor,
};
