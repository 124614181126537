import React from 'react';
import DataGridErrorView from 'components/DataGridErrorView/DataGridErrorView';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, TablePagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_ASSOCIATES_SORT_ORDERING } from 'state/ducks/associatesPage/constants';
import { rowsPerPageOptions, sortMapping } from '../../constants/index';
import { concatenateAndPrepend } from '../../lib/utils';
import { sortOptions, columns } from './constants';
import { updateRowsPerPage } from '../../state/ducks/associatesPage/actions';
import { StyledZebraStripedDataGrid } from './style';

function AssociateTable({
  currentPage,
  onPageChange,
  onSortChange,
  isErrored,
  isLoading,
  setPage,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const associates = useSelector(
    (state) => state.associatesPage.pageOfAssociates
  );
  const rowsPerPage = useSelector(
    (state) => state.associatesPage.cache.rowsPerPage
  );

  const handleSortModelChange = (gridSortModel) => {
    if (gridSortModel.length > 0 && !isLoading) {
      const { field, sort } = gridSortModel[0];
      onSortChange(
        concatenateAndPrepend(sortMapping[sort], sortOptions[field], [])
      );
    } else {
      onSortChange(DEFAULT_ASSOCIATES_SORT_ORDERING.join(','));
    }
  };

  if (isErrored && !isLoading) {
    return <DataGridErrorView />;
  }

  return (
    <StyledZebraStripedDataGrid
      className="data-grid"
      rows={associates}
      columns={columns}
      disableColumnMenu
      disableColumnFilter
      disableRowSelectionOnClick
      paginationMode="server"
      sortingMode="server"
      onRowClick={({ id }) => {
        navigate(`/associates/${id}/work-preferences`);
      }}
      loading={isLoading}
      getRowId={(row) => row.uniqueId}
      onSortModelChange={handleSortModelChange}
      localeText={{ noRowsLabel: 'There are no associates to display' }}
      css={{
        '.MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
      }}
      slots={{
        // eslint-disable-next-line react/no-unstable-nested-components
        footer: () => (
          <>
            <Divider />
            <Box
              css={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                height: '52px',
              }}
            >
              <TablePagination
                as="div"
                page={currentPage}
                count={1000000000}
                onPageChange={(event, value) => {
                  onPageChange(value);
                }}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => {
                  setPage(0);
                  dispatch(updateRowsPerPage(event.target.value));
                }}
                rowsPerPageOptions={rowsPerPageOptions}
                labelDisplayedRows={({ from, to }) => `${from}–${to}`}
              />
            </Box>
          </>
        ),
      }}
    />
  );
}

export default AssociateTable;
