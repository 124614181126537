import isEmpty from 'lodash/isEmpty';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotFoundItemSecondary, StyledAssociates, StyledEmpty } from '../style';
import Associate from './Associate';
import { setViewAssociate } from '../../../state/ducks/viewAssociate/actions';
import { LoadingWrapper } from '../../../styles/components';
import Spinner from '../../Spinner/Spinner';

function Associates({ associates, isLoading, handleAssignAssociateClick }) {
  const suggestAssociateJob = useSelector(
    ({ suggestAssociate: { job } }) => job
  );
  const dispatch = useDispatch();
  if (isLoading) {
    return (
      <LoadingWrapper className="associates-loading">
        <Spinner height="60" width="60" color="rgba(0, 0, 0, 0.1)" />
      </LoadingWrapper>
    );
  }
  if (isEmpty(associates)) {
    return (
      <Stack alignItems="center" id="matched-associates">
        <StyledEmpty id="empty-matched-associates" />
        <Typography variant="subtitle1" css={{ marginTop: '8px' }}>
          No Matched Associates
        </Typography>
        <NotFoundItemSecondary>
          The advanced search will allow you to change the search to find
          matches.
        </NotFoundItemSecondary>
      </Stack>
    );
  }
  return (
    <StyledAssociates id="matched-associates">
      {associates.map((associate) => (
        <Associate
          key={associate.user_id}
          associate={associate}
          storeLocationId={suggestAssociateJob.store_location_id}
          onShowAssociateDetails={(_associate) => {
            dispatch(setViewAssociate(_associate));
          }}
          handleAssignAssociateClick={handleAssignAssociateClick}
        />
      ))}
    </StyledAssociates>
  );
}

export default Associates;
