const camelToSnake = (value) => value.replace(/([A-Z])/g, '_$1').toLowerCase();

export const camelToKebab = (value) =>
  value.replace(/(?<!^)([A-Z])/g, '-$1').toLowerCase();

export const snakeToCamel = (value) =>
  value.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  );

export const snakeToKebab = (value) => value.replaceAll('_', '-');

export const objectKeysCamelToSnake = (object) =>
  Object.keys(object).reduce(
    (result, key) => ({
      ...result,
      [camelToSnake(key)]: object[key],
    }),
    {}
  );

export const objectKeysSnakeToCamel = (object) =>
  Object.keys(object).reduce(
    (result, key) => ({
      ...result,
      [snakeToCamel(key)]: object[key],
    }),
    {}
  );
