import * as types from './types';

const jobDetailsReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    job: {},
    isJobLoading: false,
    isJobErrored: false,
  },
  action
) => {
  switch (action.type) {
    case types.SET_JOB:
      return {
        ...state,
        job: action.payload,
      };
    case types.SET_IS_JOB_LOADING:
      return { ...state, isJobLoading: action.payload };
    case types.SET_IS_JOB_ERRORED:
      return { ...state, isJobErrored: action.payload };
    default:
      return state;
  }
};

export default jobDetailsReducer;
