import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AvailableSchedule from './AvailableSchedule';
import TimeOffSchedule from './TimeOffSchedule';
import { isSameDayOrWithinInterval } from '../utils';

function ScheduleCollapsableHeader({
  associate,
  selectedDay,
  overallTimeUnavailable,
}) {
  const { palette } = useTheme();

  return (
    <Box sx={{ margin: '16px 0' }}>
      {overallTimeUnavailable.some(
        (unavailableTime) =>
          isSameDayOrWithinInterval(
            unavailableTime,
            associate.profile.timezone,
            selectedDay
          ) && !unavailableTime.type
      ) ? (
        overallTimeUnavailable
          .filter(
            (unavailableTime) =>
              isSameDayOrWithinInterval(
                unavailableTime,
                associate.profile.timezone,
                selectedDay
              ) && !unavailableTime.type
          )
          .map((unavailableTime, index) => (
            <Box
              id={`rounded-box-${index}`}
              key={unavailableTime.start_datetime + unavailableTime.id}
              sx={{
                borderRadius: '10px',
                border: '1px solid #c6cace',
                padding: '8px 16px',
                margin: '8px 16px',
                backgroundColor: palette.backgroundColor.primary,
              }}
            >
              {unavailableTime.job_start_after ? (
                <AvailableSchedule
                  associate={associate}
                  unavailableTime={unavailableTime}
                  selectedDay={selectedDay}
                />
              ) : (
                <TimeOffSchedule
                  associate={associate}
                  unavailableTime={unavailableTime}
                  selectedDay={selectedDay}
                />
              )}
            </Box>
          ))
      ) : (
        <Typography
          variant="subtitle2"
          id="no-work-div"
          sx={{
            justifyContent: 'center',
            display: 'flex',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          No scheduled work for this day
        </Typography>
      )}
    </Box>
  );
}

export default ScheduleCollapsableHeader;
