import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import React from 'react';
import Accordion from '@mui/material/Accordion';
import { Avatar, Tooltip } from '@mui/material';
import { determineDisabledText } from 'components/JobTable/utils';
import { determineAddress } from 'components/AssociateDetails/utils';
import { useSelector } from 'react-redux';
import {
  AssociateDetail,
  AssociateName,
  NoAssociate,
  NoAssociateInfo,
  StyledAccordionSummary,
  StyledEmailLink,
  StyledPhoneNumber,
  SuggestButton,
  SuggestButtonWrapper,
} from '../style';
import { determineInitials } from '../../Menu/utils';

function AssociateCollapsableHeader({
  user,
  associateNameClick,
  suggestAssociateClick,
  status,
}) {
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const isSingleMode = Array.from(centralMode.values())[0] === 'single';
  const isCanceled = status === 'Canceled';
  return (
    <Accordion defaultExpanded>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        Associate
      </StyledAccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {user ? (
            <>
              <Grid item xs={2}>
                <Avatar css={{ height: '112px', width: '112px' }}>
                  {determineInitials(user.full_name)}
                </Avatar>
              </Grid>
              <Grid item xs={10}>
                <AssociateName
                  underline="hover"
                  id="associate-full-name"
                  onClick={associateNameClick}
                >
                  {user.full_name}
                </AssociateName>
                <AssociateDetail id="associate-employee-id">
                  {user.external_id}
                </AssociateDetail>
                <AssociateDetail id="associate-title">
                  {user.title}
                </AssociateDetail>
                {user.profile.address && (
                  <AssociateDetail id="associate-address">
                    {user.profile.address}
                  </AssociateDetail>
                )}
                {(user.profile.city ||
                  user.profile.state ||
                  user.profile.postal_code) && (
                  <AssociateDetail id="associate-full-address">
                    {determineAddress(
                      user.profile.city,
                      user.profile.state,
                      user.profile.postal_code
                    )}
                  </AssociateDetail>
                )}
                <StyledEmailLink
                  href={`mailto:${user.email}`}
                  id="associate-email"
                >
                  {user.email}
                </StyledEmailLink>
                {user.phone_number && (
                  <StyledPhoneNumber id="associate-phone-number">
                    {user.phone_number}
                  </StyledPhoneNumber>
                )}
              </Grid>
            </>
          ) : (
            <Grid item xs={12} id="no-associates-section">
              <NoAssociate id="no-associate">No Associates</NoAssociate>
              {isSingleMode && (
                <>
                  <NoAssociateInfo id="no-associate-info">
                    Suggest associates by clicking the button below.
                  </NoAssociateInfo>
                  <Tooltip title={determineDisabledText({ isCanceled })} arrow>
                    <SuggestButtonWrapper id="suggest-button-wrapper">
                      <SuggestButton
                        id="details-suggest-button"
                        variant="outlined"
                        disabled={isCanceled}
                        onClick={suggestAssociateClick}
                      >
                        Suggest Associate
                      </SuggestButton>
                    </SuggestButtonWrapper>
                  </Tooltip>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default AssociateCollapsableHeader;
