import React from 'react';
import { Box } from '@mui/system';
import { IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { determineBatchEditHeader } from '../utils';

function BatchHeader({ currentMode, onClick }) {
  return (
    <Box css={{ padding: '16px' }}>
      <Stack
        direction="row"
        alignItems="flex-start"
        css={{
          justifyContent: 'space-between',
          alignItems: 'center',
          columnGap: '16px',
        }}
        spacing={1}
      >
        <Typography id="batch-edit-header" variant="h6">
          {determineBatchEditHeader[currentMode]}
        </Typography>
        <IconButton id="batch-edit-close" onClick={onClick}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </Box>
  );
}

export default BatchHeader;
