import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import React from 'react';
import { defaultColor } from '../../../styles/theme';

function BatchNameTextField({ onChange, shouldShowBatchNameInput }) {
  if (!shouldShowBatchNameInput) {
    return null;
  }

  return (
    <Box
      css={{
        bottom: 0,
        padding: '16px',
        backgroundColor: defaultColor,
      }}
    >
      <Box>
        <TextField
          id="batch-name-text-field"
          label="Batch Name (optional)"
          variant="outlined"
          css={{ width: '100%' }}
          onChange={onChange}
        />
      </Box>
    </Box>
  );
}

export default BatchNameTextField;
