import { Box, Chip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { format } from 'date-fns-tz';
import React from 'react';
import { hovered } from 'styles/theme/colors';
import { calculateTimeFrame, determineTimeOff } from '../utils';

function TimeOffSchedule({ unavailableTime, selectedDay, associate }) {
  const { palette } = useTheme();

  return (
    <>
      <Box
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '8px',
        }}
      >
        <Box>
          <Typography variant="subtitle2">
            {`${format(selectedDay, 'LLLL d, yyyy', {
              timeZone: associate.profile.timezone,
            })}`}
          </Typography>
          <Typography variant="subtitle2">
            {' '}
            {determineTimeOff(
              unavailableTime.start_datetime,
              unavailableTime.end_datetime,
              associate.profile.timezone
            )}
          </Typography>
        </Box>
        {determineTimeOff(
          unavailableTime.start_datetime,
          unavailableTime.end_datetime,
          associate.profile.timezone
        ) !== 'All day' && (
          <Chip
            size="small"
            variant="outlined"
            css={{
              backgroundColor: hovered,
              textColor: palette.text.primary,
            }}
            label={calculateTimeFrame(
              unavailableTime.end_datetime,
              unavailableTime.start_datetime
            )}
          />
        )}
      </Box>
      <Typography variant="body2">Time Off</Typography>
    </>
  );
}

export default TimeOffSchedule;
