/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import { ExpandMore } from '@mui/icons-material';
import { format, startOfDay, startOfMonth, addWeeks } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
  displayAvailability,
  displayCommuteMiles,
  displayMaxDailyHours,
} from 'components/AssociateWorkPreferences/utils';
import { AvailabilityTypography } from 'components/AssociateWorkPreferences/components/style';
import AssociateAvailabilityInfoRow from 'components/AssociateWorkPreferences/components/AssociateAvailabilityInfoRow';
import axios from 'axios';
import { getAssociatePreferences } from '../../../services/user';
import Error from './Error';
import {
  formatTimeRange,
  scrollIntoView,
  convertWeekArraysToAvailabilityDays,
  determineWeekScheduleForDate,
  getWeeksInMonth,
} from '../../../lib/utils';
import LoadingSpinner from './LoadingSpinner';
import { formatMonthlySchedule } from '../utils';

function WorkPreferencesCollapsableHeader({
  associate,
  selectedDay,
  setMonthlyAvailability,
}) {
  const availabilityEffectiveDate = zonedTimeToUtc(
    startOfDay(selectedDay),
    associate.profile.timezone
  );
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [workPreferences, setWorkPreferences] = useState(null);
  const [workPreferencesError, setWorkPreferencesError] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [monthlyData, setMonthlyData] = useState(null);

  const handleDayChange = (date) => {
    setIsLoading(true);
    setWorkPreferencesError(null);
    if (date) {
      const monthStart = startOfMonth(date);

      const numberOfWeeks = getWeeksInMonth(date);
      const weekDates = Array.from({ length: numberOfWeeks }, (_, index) =>
        addWeeks(monthStart, index)
      ).filter((weekDate) => weekDate);

      const preferencePromises = weekDates.map((weekDate) => {
        const effectiveDate = zonedTimeToUtc(
          startOfDay(weekDate),
          associate.profile.timezone
        );
        return getAssociatePreferences({
          userId: associate.id,
          effectiveDate,
        });
      });

      Promise.all(preferencePromises)
        .then((allPreferences) => {
          setIsLoading(false);

          const combinedPreferences =
            convertWeekArraysToAvailabilityDays(allPreferences);

          const formattedMonthlyData = formatMonthlySchedule(
            combinedPreferences,
            availabilityEffectiveDate
          );

          setMonthlyAvailability(formattedMonthlyData);

          setMonthlyData(formattedMonthlyData);

          const week = determineWeekScheduleForDate(formattedMonthlyData, date);

          setWorkPreferences(week);
        })
        .catch((error) => {
          setIsLoading(false);
          if (!axios.isCancel(error)) {
            setWorkPreferencesError(
              error.message ||
                'There was a problem retrieving the Associate record'
            );
          }
          setWorkPreferencesError(error);
        });
    }
  };
  useEffect(() => {
    const newMonth = format(selectedDay, 'yyyy-MM');

    if (newMonth !== currentMonth) {
      setCurrentMonth(newMonth);
      handleDayChange(selectedDay);
    } else if (monthlyData) {
      const week = determineWeekScheduleForDate(monthlyData, selectedDay);
      setWorkPreferences(week);
    }
  }, [selectedDay]);

  useEffect(() => {
    scrollIntoView(expanded, 'work-preferences-accordion-details', 400);
  }, [expanded]);

  return (
    <Accordion
      expanded={expanded}
      square
      onChange={() => setExpanded(!expanded)}
      css={{
        border: 0,
        boxShadow: 'unset',
        borderTop: '1px solid #c6cace',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore fontSize="medium" />}
        css={{
          paddingBottom: 0,
        }}
        aria-controls="panel1b-content"
        id="panel1b-header"
      >
        <Box id="work-preferences">
          <Typography variant="subtitle1">Work Preferences</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        id="work-preferences-accordion-details"
        css={{ padding: '16px', paddingTop: 0 }}
      >
        {isLoading && <LoadingSpinner id="associate-preferences-loading" />}

        {workPreferencesError && !isLoading ? (
          <Error
            id="associate-preferences-error"
            doRetry={() => {
              setWorkPreferencesError(null);
              handleDayChange(selectedDay);
            }}
          />
        ) : !workPreferences ||
          !Array.isArray(workPreferences) ||
          workPreferences.length === 0 ||
          !workPreferences[0] ||
          !workPreferences[0].preferences ? (
          <Typography
            variant="subtitle2"
            id="no-work-preferences-div"
            css={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            No work preferences for this associate
          </Typography>
        ) : (
          <>
            {(() => {
              const workPreference = workPreferences[0];

              return (
                <>
                  <AssociateAvailabilityInfoRow label="Home store only">
                    <AvailabilityTypography id="home-store" variant="body1">
                      {displayAvailability(
                        workPreference.preferences.myHomeStoreOnly
                      )}
                    </AvailabilityTypography>
                  </AssociateAvailabilityInfoRow>

                  {!workPreference.preferences.myHomeStoreOnly && (
                    <>
                      <AssociateAvailabilityInfoRow label="Willing to drive">
                        <AvailabilityTypography
                          id="max-commute-miles"
                          variant="body1"
                        >
                          {displayCommuteMiles(
                            workPreference.preferences.maximumCommuteMiles
                          )}
                        </AvailabilityTypography>
                      </AssociateAvailabilityInfoRow>

                      <AssociateAvailabilityInfoRow label="Travel and stay overnight">
                        <AvailabilityTypography
                          id="overnight-stay"
                          variant="body1"
                        >
                          {displayAvailability(
                            workPreference.preferences.allowOvernightStay
                          )}
                        </AvailabilityTypography>
                      </AssociateAvailabilityInfoRow>
                    </>
                  )}

                  <AssociateAvailabilityInfoRow label="Preferred hours a week">
                    <AvailabilityTypography
                      id="preferred-weekly-hours"
                      variant="body1"
                    >
                      {`${workPreference.preferences.preferredWeeklyHours} hours`}
                    </AvailabilityTypography>
                  </AssociateAvailabilityInfoRow>

                  <AssociateAvailabilityInfoRow label="Maximum hours per day">
                    <AvailabilityTypography
                      id="max-daily-hours"
                      variant="body1"
                    >
                      {displayMaxDailyHours(
                        workPreference.preferences.preferredDailyHours
                      )}
                    </AvailabilityTypography>
                  </AssociateAvailabilityInfoRow>
                </>
              );
            })()}

            {workPreferences.map((availabilityData) => (
              <AssociateAvailabilityInfoRow
                key={availabilityData.start}
                id={`availability-${availabilityData.day}`}
                label={
                  availabilityData &&
                  format(new Date(0, 0, availabilityData.day), 'EEEE')
                }
              >
                <Box
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                  }}
                >
                  {!availabilityData.time_ranges ? (
                    <AvailabilityTypography
                      id="time-slot-not-available"
                      variant="body1"
                    >
                      Not Available
                    </AvailabilityTypography>
                  ) : (
                    availabilityData.time_ranges.map((time, index) => (
                      <AvailabilityTypography
                        // eslint-disable-next-line react/no-array-index-key
                        key={`time-slot-${index}`}
                        id={`time-slot-${index}`}
                        variant="body1"
                      >
                        {formatTimeRange(time)}
                      </AvailabilityTypography>
                    ))
                  )}
                </Box>
              </AssociateAvailabilityInfoRow>
            ))}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default WorkPreferencesCollapsableHeader;
