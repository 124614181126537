import React from 'react';
import { DesktopTimePicker } from '@mui/x-date-pickers-pro';
import { utcToZonedTime } from 'date-fns-tz';
import { isValid } from 'date-fns';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { determineColor } from '../utils';

function TimeEdit({
  timeEdit,
  dateEdit,
  isLoading,
  selectedTime,
  errorTime,
  errorDate,
  setSelectedTime,
  setTimeEdit,
  setErrorTime,
  selectedDate,
  setSelectedDate,
  setDateEdit,
  isDateWithinRange,
  isEditable,
}) {
  const batchJob = useSelector(
    (state) => state.staffingWorkPage.editJob.batchJobs
  )[0];
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const currentMode = Array.from(centralMode.values())[0];
  const isSingleModeOn = currentMode === 'single';
  const color = determineColor(isEditable);

  if (timeEdit) {
    return (
      <DesktopTimePicker
        autoFocus={!(isSingleModeOn && (dateEdit || errorDate))}
        label="Start Time"
        value={isSingleModeOn ? selectedDate.edit : selectedTime.edit}
        disabled={isLoading}
        minTime={
          isSingleModeOn &&
          batchJob &&
          utcToZonedTime(
            batchJob.created_job_start_after,
            batchJob.store_location_timezone
          )
        }
        maxTime={
          isSingleModeOn &&
          batchJob &&
          utcToZonedTime(
            batchJob.created_job_finish_before,
            batchJob.store_location_timezone
          )
        }
        onChange={(event) => {
          if (isSingleModeOn) {
            setSelectedDate({
              ...selectedDate,
              edit: isValid(event) ? event : null,
            });
            return;
          }
          setSelectedTime({
            ...selectedTime,
            edit: isValid(event) ? event : null,
          });
        }}
        onError={(value) => {
          setErrorTime(value);
          if (!isDateWithinRange) {
            setDateEdit(true);
          }
        }}
        css={{ width: '100%', marginTop: '24px' }}
        slotProps={{
          textField: {
            id: 'batch-edit-time-picker',
            helperText: errorTime
              ? 'Time is outside available range'
              : undefined,
          },
        }}
        disableIgnoringDatePartForTimeValidation={isSingleModeOn}
      />
    );
  }
  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '24px',
      }}
    >
      <Box>
        <Typography id="current-time-label" variant="subtitle3" css={color}>
          Start Time
        </Typography>
        <Typography id="current-time" variant="body1" css={color}>
          {selectedTime.view}
        </Typography>
      </Box>
      {isEditable && (
        <Button
          id="batch-edit-time"
          variant="text"
          onClick={() => setTimeEdit(true)}
          disabled={isLoading}
        >
          Edit
        </Button>
      )}
    </Box>
  );
}

export default TimeEdit;
