/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { utcToZonedTime, format } from 'date-fns-tz';
import { useSelector, useDispatch } from 'react-redux';
import {
  setBatchJobs,
  setFocusedJobId,
  setIncludesAutoStaffedJob,
  setSelectedEditField,
} from 'state/ducks/staffingWorkPage/actions';
import { determineDisabledText } from '../utils';
import EditPencilOverlay from './EditPencilOverlay';

function JobDateDrawerEditing({ job }) {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const status = job.status_central;
  const startDate = job.job_start_after;
  const storeTimezone = job.store_location_timezone;
  const isCompletedDateTime = status === 'Completed';
  const isCanceled = status === 'Canceled';
  const advantageSourceSystemName = job.advantage_source_system_name;
  const isNotEditable = advantageSourceSystemName !== 'JET';
  const isDisabled = isCanceled || isNotEditable || isCompletedDateTime;
  const startDateWithTimezone = utcToZonedTime(startDate, storeTimezone);
  const day = format(startDateWithTimezone, 'EEEE', {
    timeZone: storeTimezone,
  });
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const currentMode = Array.from(centralMode.values())[0];
  const isSingleMode = currentMode === 'single';
  const isEditDrawerEnabled =
    isSingleMode && !isDisabled && job.advantage_source_system_name === 'JET';
  const disabledText = determineDisabledText({
    isNotEditable,
    isCompletedDateTime,
    isCanceled,
  });

  const handleOpenBatchEdit = () => {
    if (isEditDrawerEnabled) {
      dispatch(setFocusedJobId(job.id));
      dispatch(setSelectedEditField('date'));
      dispatch(setBatchJobs([job]));
      dispatch(
        setIncludesAutoStaffedJob(
          job.status_attributes.includes('auto_staffed')
        )
      );
    }
  };

  return (
    <Tooltip title={disabledText}>
      <Box
        onClick={handleOpenBatchEdit}
        className="job-date-wrapper"
        css={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          ...(isSingleMode && !disabledText
            ? {
                '&:hover > div': {
                  display: 'flex',
                },
              }
            : {}),
        }}
      >
        <Box>
          <Typography
            variant="body2"
            css={{
              fontWeight: '600',
              color: palette.secondary.main,
            }}
          >
            {format(startDateWithTimezone, 'M/d/yyyy', {
              timeZone: storeTimezone,
            })}
          </Typography>
          <Typography variant="body2" css={{ color: palette.secondary.main }}>
            {day}
          </Typography>
        </Box>
        <EditPencilOverlay id="date-edit" />
      </Box>
    </Tooltip>
  );
}

export default JobDateDrawerEditing;
