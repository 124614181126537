import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Box, Button, Popover } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { defaultColor } from '../../../styles/theme';
import LegendDay from './LegendDay';

function CalendarLegend() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'calendar-legend-popover' : undefined;

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const legendDays = [
    {
      type: 'highlighted',
      color: theme.palette.primary.main,
      label: 'Day with accepted work',
    },
    {
      type: 'highlighted',
      color: theme.palette.warning.main,
      label: 'Day with pending work',
    },
    {
      type: 'circle',
      label: 'Current date',
    },
    {
      type: 'circle',
      label: 'Partial availability',
      customStyles: {
        backgroundImage:
          'repeating-linear-gradient(135deg, #E2E7EC, #E2E7EC 6px, transparent 5px, transparent 10px)',
        borderColor: '#e2e7ec',
      },
    },
    {
      type: 'circle',
      label: 'Full day unavailable',
      textColor: theme.palette.text.disabled,
      customStyles: {
        backgroundColor: '#e1e3e5',
        border: 'none',
      },
    },
  ];

  return (
    <Box
      css={{
        padding: '16px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="subtitle1">Schedule</Typography>
      <Box>
        <Button
          onClick={handleOpen}
          css={{
            color: theme.palette.primary.main,
            textTransform: 'none',
          }}
          id="calendar-legend-button"
        >
          <HelpOutlineIcon
            css={{
              width: '20px',
              height: '20px',
              paddingRight: '4px',
            }}
            color="primary"
          />
          Calendar Legend
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box
            css={{
              padding: '16px',
              gap: '16px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {legendDays.map((legendDay) => (
              <LegendDay {...legendDay} />
            ))}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '16px',
              }}
            >
              <LegendDay
                type="circle"
                customStyles={{
                  backgroundColor: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                }}
                textColor={defaultColor}
              />

              <LegendDay
                type="circle"
                customStyles={{
                  backgroundImage:
                    'repeating-linear-gradient(135deg, #E2E7EC, #E2E7EC 6px, transparent 5px, transparent 10px)',
                  borderColor: theme.palette.primary.main,
                }}
              />

              <LegendDay
                type="circle"
                label="Selected day"
                customStyles={{
                  backgroundColor: '#E1E3E5',
                  borderColor: theme.palette.primary.main,
                }}
                textColor={theme.palette.text.disabled}
              />
            </Box>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
}

export default CalendarLegend;
