import { Box, List, TablePagination } from '@mui/material';
import styled from '@emotion/styled/macro';
import { theme, defaultColor } from 'styles/theme';
import ZebraStripedDataGrid from 'components/ZebraStripedDataGrid/ZebraStripedDataGrid';

export const BatchDetailsPagination = styled(TablePagination)`
  display: flex;
  align-items: center;
  justify-content: right;
  height: 52px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${theme.palette.backgroundColor.secondary};
  overflow: hidden;
  padding-right: 30px;
  border-bottom-right-radius: 8px;
`;

export const StyledZebraStripedDataGrid = styled(ZebraStripedDataGrid)`
  border: none;
  border-bottom-right-radius: 8px;
  .MuiDataGrid-footerContainer {
    height: 52px;
    width: 100%;
    background-color: white;
    border-bottom-right-radius: 8px;
  }
`;

export const Statuses = styled(List)`
  && {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0;
    .Mui-selected {
      color: ${defaultColor};
      background-color: ${theme.palette.primary.main};
      &:hover {
        background-color: ${theme.palette.primary.main};
      }
    }
  }
`;

export const BatchDetailsHeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #c6cace;
  padding: 16px 24px;
`;
