import { datadogLogs } from '@datadog/browser-logs';
import HTTP from '../../../core/http';
import { URL } from '../../../core/config';

import { abort } from '../abort/operations';
import { setIsJobErrored, setIsJobLoading, setJob } from './actions';

export const fetchJobDetails = (id) => async (dispatch, getState) => {
  const sectionKey = 'fetchJobDetails';

  await dispatch(abort(sectionKey));
  const {
    abort: { abortControllers },
  } = getState();
  const { signal } = abortControllers[sectionKey];
  const endpoint = URL.jobs;

  dispatch(setIsJobLoading(true));

  return HTTP.get(endpoint, {
    signal,
    params: { id },
  })
    .then((response) => {
      dispatch(setIsJobLoading(false));
      if (response.data.results.length === 1 && response.data.results[0]) {
        dispatch(setJob(response.data.results[0]));
      } else {
        dispatch(setIsJobErrored(true));
        throw new Error('Error while fetching job details');
      }
    })
    .catch((error) => {
      dispatch(setIsJobLoading(false));
      if (signal && !signal.aborted) {
        dispatch(setIsJobErrored(true));
        datadogLogs.logger.error(
          '[JobDetails] Error while fetching job details',
          {
            url: endpoint,
            error,
          }
        );
      }
    });
};
