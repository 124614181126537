import React from 'react';

import Link from '@mui/material/Link';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as operations from 'state/ducks/jobDetails/operations';
import { DEFAULT_VIEW_ASSOCIATE } from '../../../state/ducks/viewAssociate/constants';
import { setSuggestAssociateJob } from '../../../state/ducks/suggestAssociate/actions';
import { setViewAssociate } from '../../../state/ducks/viewAssociate/actions';
import { DEFAULT_SUGGEST_ASSOCIATE_JOB } from '../../../state/ducks/suggestAssociate/constants';
import { setFocusedJobId } from '../../../state/ducks/staffingWorkPage/actions';

function JobID({
  jobId,
  externalIdentifier,
  advantageSourceSystemName,
  dataDogActionName,
}) {
  const dispatch = useDispatch();

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      component="button"
      onClick={() => {
        dispatch(setFocusedJobId(jobId));
        dispatch(operations.fetchJobDetails(jobId));
        dispatch(setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB));
        dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
      }}
      className="job-reference-id"
      sx={{
        textAlign: 'left',
      }}
      data-dd-action-name={dataDogActionName}
    >
      <Box>{externalIdentifier}</Box>

      {advantageSourceSystemName && (
        <Box className="advantage-source-system-name">
          {advantageSourceSystemName}
        </Box>
      )}
    </Link>
  );
}

export default JobID;
