import React, { forwardRef, useEffect } from 'react';
import {
  Button,
  DialogContent,
  Typography,
  Dialog,
  Slide,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled/macro';
import { useLocation } from 'react-router-dom';
import { DEFAULT_BATCH } from '../../state/ducks/dialog/constants';
import { setBatch } from '../../state/ducks/dialog/actions';

// eslint-disable-next-line react/no-unstable-nested-components
const Transition = forwardRef((props, ref) => (
  <Slide direction="right" ref={ref} {...props} />
));

const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    align-items: flex-start;
    margin-top: 220px;
  }
`;

function BatchDialog() {
  const batch = useSelector((state) => state.dialog.batch);
  const location = useLocation();
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setBatch(DEFAULT_BATCH));
  };

  useEffect(() => {
    if (isEmpty(batch)) {
      return;
    }

    onClose();
  }, [location]);

  if (isEmpty(batch)) {
    return null;
  }

  const {
    dialog: { text: dialogText },
    button: { onClick, text: buttonText },
  } = batch;

  return (
    <StyledDialog
      open={!isEmpty(batch)}
      onClose={() => onClose()}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '760px',
        },
      }}
      TransitionComponent={Transition}
    >
      <DialogContent
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '6px 0 6px 16px',
        }}
      >
        <Typography
          variant="body1"
          as="span"
          id="batch-dialog-text"
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {dialogText}
          <Button
            variant="contained"
            id="batch-select-all-filter-set"
            size="small"
            css={{ marginLeft: '16px' }}
            onClick={() => {
              onClick();
              onClose();
            }}
          >
            {buttonText}
          </Button>
        </Typography>
        <IconButton
          id="batch-dialog-close"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon
            css={{
              height: '25px',
              width: '25px',
            }}
          />
        </IconButton>
      </DialogContent>
    </StyledDialog>
  );
}

export default BatchDialog;
