import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function RowIndex({ gridApiRef, params, page, rowsPerPage }) {
  const { palette } = useTheme();

  const getRowIndex = useCallback(
    (id) => gridApiRef.current.getSortedRowIds().indexOf(id),
    [params.api]
  );

  const rowIndex = getRowIndex(params.id);
  const currentPage = page;
  const pageSize = rowsPerPage;
  const rowCount = currentPage * pageSize + rowIndex + 1;

  return (
    <Box
      css={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
      }}
    >
      <Typography
        variant="body2"
        css={{
          color: palette.text.secondary,
        }}
      >
        {rowCount}
      </Typography>
    </Box>
  );
}

export default RowIndex;
