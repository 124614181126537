import {
  Chip,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useTheme } from '@mui/material/styles';
import { statusAttributesMapping } from '../JobTable/constants';
import { updateSelectedFilterItems } from '../../state/ducks/filters/actions';
import { fetchFilterItems } from '../../state/ducks/filters/operations';

const sectionKey = 'callToAction';
const callToActionEndpoint = '/jobs/grouped-by/call-to-action';
const excludedObjectProp = 'auto_confirmed';

const callToActionRequest = {
  sectionKey,
  searchQuery: '',
  apiSearchKey: '',
  determineSearchParams: null,
  endpoint: callToActionEndpoint,
  params: {},
  parseData: (items) =>
    new Map(items.map((item) => [item.call_to_action, item.count])),
};

function CallToActionBar() {
  const [isLoading, setIsLoading] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const { palette } = useTheme();

  const {
    [sectionKey]: callToActionSelectedFilterItems,
    ...otherSelectedFilterItems
  } = useSelector((state) => state.filters.selectedFilterItems);

  const callToActionFilterItems = useSelector(
    (state) => state.filters.filterItems.callToAction
  );
  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    setIsLoading(true);
    dispatch(fetchFilterItems(callToActionRequest))
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsErrored(true);
      });
  }, [otherSelectedFilterItems]);

  const handleChange = (checked, callToActionChecked) => {
    if (!checked) {
      const removedFilter = new Map(
        Array.from(callToActionSelectedFilterItems).reduce(
          (accumulator, callToActionItem) =>
            callToActionItem[0] === callToActionChecked
              ? accumulator
              : [...accumulator, callToActionItem],
          []
        )
      );
      dispatch(updateSelectedFilterItems({ callToAction: removedFilter }));
      return;
    }
    dispatch(
      updateSelectedFilterItems({
        callToAction: new Map([
          ...callToActionSelectedFilterItems,
          [callToActionChecked, callToActionChecked],
        ]),
      })
    );
  };

  const displayStatusCount = (count, color, label) => {
    if (isErrored) {
      return null;
    }
    if (isLoading) {
      return (
        <Skeleton
          variant="circular"
          id="call-to-action-skeleton"
          width={24}
          height={24}
          css={{ marginRight: '16px' }}
        />
      );
    }
    return (
      <Chip
        label={count > 999 ? '999+' : count}
        id={`call-to-action-${label}`}
        variant="filled"
        size="small"
        color={color}
        sx={{ color: 'white', marginRight: '16px' }}
      />
    );
  };

  const { [excludedObjectProp]: excluded, ...filteredStatusAttributesMapping } =
    statusAttributesMapping;

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ paddingTop: '6px', paddingBottom: '4px', backgroundColor: 'white' }}
    >
      <Typography
        variant="subtitle3"
        color={palette.text.primary}
        css={{ marginLeft: '16px' }}
      >
        Quick Filters:
      </Typography>
      {Object.entries(filteredStatusAttributesMapping).map(
        ([name, { label, color }], index, { length }) => {
          const isNotLast = index !== length - 1;
          const count = callToActionFilterItems.get(name) || 0;
          const isChecked = Array.from(
            callToActionSelectedFilterItems.keys()
          ).includes(name);
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              key={label}
              sx={{ marginLeft: '16px' }}
            >
              <FormControlLabel
                label={label}
                control={
                  <Checkbox
                    checked={isChecked}
                    size="small"
                    id={`${label.toLowerCase()}-checkbox`}
                    onChange={(event) =>
                      handleChange(event.target.checked, name)
                    }
                  />
                }
                sx={{ marginRight: '8px' }}
              />
              {displayStatusCount(count, color, label)}
              {isNotLast && (
                <Divider orientation="vertical" sx={{ height: '26px' }} />
              )}
            </Box>
          );
        }
      )}
    </Box>
  );
}

export default CallToActionBar;
