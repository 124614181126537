import * as types from './types';

export const setJob = (job) => ({
  type: types.SET_JOB,
  payload: job,
});

export const setIsJobLoading = (isJobLoading) => ({
  type: types.SET_IS_JOB_LOADING,
  payload: isJobLoading,
});

export const setIsJobErrored = (isJobErrored) => ({
  type: types.SET_IS_JOB_ERRORED,
  payload: isJobErrored,
});
