import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LegendItem, CircleBox, HighlightedDay, DayBox } from '../style';

function LegendDay({ type, color, label, customStyles, textColor }) {
  const theme = useTheme();
  const number = (
    <Typography variant="body2" color={textColor || theme.palette.text.primary}>
      1
    </Typography>
  );

  return (
    <LegendItem key={label}>
      <Box>
        {type === 'highlighted' ? (
          <DayBox>
            {number}
            <HighlightedDay color={color} />
          </DayBox>
        ) : (
          <CircleBox customStyles={customStyles}>{number}</CircleBox>
        )}
      </Box>
      {label && (
        <Typography variant="body2" color={theme.palette.text.primary}>
          {label}
        </Typography>
      )}
    </LegendItem>
  );
}

export default LegendDay;
