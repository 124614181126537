/* eslint-disable camelcase */
import { utcToZonedTime, format } from 'date-fns-tz';
import { Box } from '@mui/system';
import {
  isSameDay,
  isBefore,
  isWithinInterval,
  startOfDay,
  endOfDay,
  startOfWeek,
  addDays,
  differenceInMilliseconds,
  formatDuration,
  intervalToDuration,
  subSeconds,
} from 'date-fns';
import { Skeleton } from '@mui/material';
import React from 'react';
import { EXCEPTIONS_TO_MATCH_DETAILS } from '../../SuggestAssociates/constants';
import { theme } from '../../../styles/theme';
import { formatNumberWithCommas } from '../../../lib/utils';

export const iconDetails = {
  missing: {
    color: 'red',
    icon: 'error',
  },
  matching: {
    color: 'green',
    icon: 'check_circle',
  },
};

const adjustEndTime = (start, end) => {
  if (
    end.getHours() === 0 &&
    end.getMinutes() === 0 &&
    !isSameDay(start, end)
  ) {
    return new Date(end.getTime() - 60000);
  }
  return end;
};

export const determineIconDetail = (exception, exceptions) =>
  iconDetails[
    exceptions.includes(exception) ||
    (exception === 'is_available' && exceptions.includes('has_remaining_hours'))
      ? 'missing'
      : 'matching'
  ];

export const determineExceptionText = (exceptionText, text, iconText) => {
  const declinedExceptionText =
    EXCEPTIONS_TO_MATCH_DETAILS.previously_declined.exception_text;
  return exceptionText === declinedExceptionText && iconText === 'error'
    ? 'Has declined this assignment'
    : text;
};

export const determineAddress = (city, state, zipcode, streetAddress) => {
  const addressParts = [city, state, zipcode, streetAddress].filter(Boolean);

  return addressParts.join(', ');
};

export const determineHomeStoreAddress = (
  chainName,
  primarySelfIdentity,
  city,
  state
) => {
  const homeStoreParts = [chainName, primarySelfIdentity, city].filter(Boolean);

  if (homeStoreParts.length === 0) {
    return state;
  }

  return state
    ? `${homeStoreParts.join(' - ')}, ${state}`
    : homeStoreParts.join(' - ');
};

export const calculateTimeFrame = (finishBeforeDate, startAfterDate) => {
  const beforeDate = new Date(finishBeforeDate);
  const afterDate = new Date(startAfterDate);

  const durationInMilliseconds = differenceInMilliseconds(
    beforeDate,
    afterDate
  );
  const duration = intervalToDuration({
    start: 0,
    end: durationInMilliseconds,
  });
  const formattedDuration = formatDuration(duration, {
    format: ['days', 'hours', 'minutes'],
  });

  return formattedDuration;
};

export const formatTimezone = (timezone, utcDateTime) => {
  if (timezone) {
    const zonedDateTime = utcToZonedTime(utcDateTime, timezone);
    const offsetString = format(zonedDateTime, 'O', { timeZone: timezone });

    return `(${offsetString})`;
  }
  return '';
};

export const determineTimeOff = (startTime, endTime, timezone) => {
  const startTimeWithTimezone = utcToZonedTime(new Date(startTime), timezone);
  const endTimeWithTimezone = utcToZonedTime(new Date(endTime), timezone);
  const timezoneAbr = format(startTimeWithTimezone, 'zzz', {
    timeZone: timezone,
  });
  const isAllDay =
    endTimeWithTimezone.getTime() - startTimeWithTimezone.getTime() >=
    24 * 60 * 60 * 1000;

  if (isAllDay) {
    return 'All day';
  }

  return `${format(startTimeWithTimezone, 'h:mm a')} - ${format(
    endTimeWithTimezone,
    'h:mm a'
  )} (${timezoneAbr})`;
};

export const showSkeleton = (from, to) => (
  <Box css={{ display: 'flex', alignItems: 'center' }}>
    {`${from}-${to} of `}
    <Skeleton width={30} height={25} css={{ marginLeft: '8px' }} />
  </Box>
);

export const determineLabelDisplayRows = (
  from,
  to,
  count,
  isJobCountLoading,
  jobCountError
) => {
  if (jobCountError) {
    return `${from}–${to}`;
  }
  if (isJobCountLoading || count === undefined) {
    return showSkeleton(from, to);
  }
  return `${from}–${to} of ${formatNumberWithCommas(count)}`;
};

export const determineBadgeColor = (overallTimeUnavailable, day) => {
  if (overallTimeUnavailable.length === 0) {
    return undefined;
  }

  const isPendingJob = overallTimeUnavailable.some(
    (job) =>
      isSameDay(new Date(job.start_datetime), day) &&
      job.confirmation_status === 'confirmation_requested' &&
      job.id
  );

  const areAllJobsAssigned = overallTimeUnavailable.some(
    (job) =>
      isSameDay(new Date(job.start_datetime), day) &&
      (job.confirmation_status === null ||
        job.confirmation_status === 'jyver_confirmed') &&
      job.id
  );

  const isPastJobAssigned = overallTimeUnavailable.some(
    (job) =>
      isSameDay(new Date(job.start_datetime), day) &&
      isBefore(new Date(job.start_datetime), new Date()) &&
      (job.confirmation_status === null ||
        job.confirmation_status === 'jyver_confirmed' ||
        job.confirmation_status === 'confirmation_requested') &&
      job.id
  );

  if (isPendingJob) {
    return theme.palette.warning.main;
  }

  if (isPastJobAssigned || areAllJobsAssigned) {
    return theme.palette.primary.main;
  }
  return undefined;
};

function isTimeWithinDay(time, dayStart, dayEnd, timezone) {
  const zonedTime = utcToZonedTime(time, timezone);
  return isWithinInterval(zonedTime, { start: dayStart, end: dayEnd });
}

function determineAvailabilityForSameDay(
  startTime,
  endTime,
  dayStart,
  dayEnd,
  timezone
) {
  const startsAtOrBeforeDayStart = startTime <= dayStart;
  const endsAtOrAfterDayEnd =
    endTime >= dayEnd ||
    (endTime.getHours() === 23 && endTime.getMinutes() === 59);
  const coversFullDay = startsAtOrBeforeDayStart && endsAtOrAfterDayEnd;

  return {
    isPartiallyAvailable:
      isTimeWithinDay(startTime, dayStart, dayEnd, timezone) ||
      isTimeWithinDay(endTime, dayStart, dayEnd, timezone),
    isFullDayOff: coversFullDay,
  };
}

const determineSingleDayAvailability = (
  accumulator,
  startTimeWithTimezone,
  adjustedEndTime,
  startOfCurrentDay,
  endOfCurrentDay,
  type
) => {
  const overlapsStart = isWithinInterval(startTimeWithTimezone, {
    start: startOfCurrentDay,
    end: endOfCurrentDay,
  });

  const overlapsEnd = isWithinInterval(adjustedEndTime, {
    start: startOfCurrentDay,
    end: endOfCurrentDay,
  });

  const startsAtOrBeforeDayStart = startTimeWithTimezone <= startOfCurrentDay;

  const endsAtOrAfterDayEnd =
    (adjustedEndTime.getHours() === 23 &&
      adjustedEndTime.getMinutes() === 59) ||
    adjustedEndTime >= endOfCurrentDay;

  const coversFullDay = startsAtOrBeforeDayStart && endsAtOrAfterDayEnd;

  return {
    isPartiallyAvailable:
      accumulator.isPartiallyAvailable ||
      type === 'partially_available' ||
      overlapsStart ||
      overlapsEnd,
    isFullDayOff:
      !accumulator.isPartiallyAvailable &&
      (accumulator.isFullDayOff || type === 'full_day_off' || coversFullDay),
  };
};

export const determineDayAvailability = (
  overallTimeUnavailable,
  timezone,
  day
) => {
  const currentDay = new Date(day);
  const startOfCurrentDay = startOfDay(currentDay);
  const endOfCurrentDay = endOfDay(currentDay);

  const { isPartiallyAvailable, isFullDayOff } = overallTimeUnavailable.reduce(
    (
      accumulator,
      { start_datetime: startDateTime, end_datetime: endDateTime, id, type }
    ) => {
      if (!id && type !== 'full_day_available') {
        const startTimeWithTimezone = type
          ? new Date(startDateTime)
          : utcToZonedTime(new Date(startDateTime), timezone);

        const endTimeWithTimezone = type
          ? new Date(endDateTime)
          : utcToZonedTime(new Date(endDateTime), timezone);

        const adjustedEndTime = adjustEndTime(
          startTimeWithTimezone,
          endTimeWithTimezone
        );

        const isValidInterval = startTimeWithTimezone <= endTimeWithTimezone;
        const isWithinTimeframe =
          isValidInterval &&
          isWithinInterval(currentDay, {
            start: startOfDay(startTimeWithTimezone),
            end: startOfDay(adjustedEndTime),
          });

        const isSingleDay = isSameDay(new Date(startDateTime), currentDay);

        if (isSingleDay) {
          return determineSingleDayAvailability(
            accumulator,
            startTimeWithTimezone,
            adjustedEndTime,
            startOfCurrentDay,
            endOfCurrentDay,
            type
          );
        }

        if (isWithinTimeframe) {
          const availabilityResult = determineAvailabilityForSameDay(
            startTimeWithTimezone,
            endTimeWithTimezone,
            startOfCurrentDay,
            endOfCurrentDay,
            timezone
          );

          return {
            isPartiallyAvailable:
              accumulator.isPartiallyAvailable ||
              availabilityResult.isPartiallyAvailable,
            isFullDayOff:
              !accumulator.isPartiallyAvailable &&
              (accumulator.isFullDayOff || availabilityResult.isFullDayOff),
          };
        }
      }

      return accumulator;
    },
    { isPartiallyAvailable: false, isFullDayOff: false }
  );

  if (isFullDayOff) {
    return 'full_day_off';
  }

  if (isPartiallyAvailable) {
    return 'partially_available';
  }

  return null;
};

const isFullDay = (day) => {
  if (!day.time_ranges?.[0]) {
    return false;
  }
  const range = day.time_ranges[0];
  return (
    range.start_hour === 0 &&
    range.start_minute === 0 &&
    range.end_hour === 23 &&
    range.end_minute === 59
  );
};
const formatLocalDateTime = (date) => format(date, "yyyy-MM-dd'T'HH:mm:ss");

export const formatMonthlySchedule = (unavailableTimes, effectiveDate) => {
  const baseDate = new Date(effectiveDate);
  const firstDayOfMonth = new Date(
    baseDate.getFullYear(),
    baseDate.getMonth(),
    1
  );
  const sunday = startOfWeek(firstDayOfMonth, { weekStartsOn: 0 });
  const flattenedUnavailableTimes = unavailableTimes.flat();

  const groupedByWeekAndDay = flattenedUnavailableTimes.reduce(
    (accumulator, item) => {
      if (!accumulator[item.week]) {
        accumulator[item.week] = {};
      }
      accumulator[item.week][item.day] = item;
      return accumulator;
    },
    {}
  );
  const formattedSchedule = Object.keys(groupedByWeekAndDay).flatMap((week) => {
    const weekData = groupedByWeekAndDay[week];

    return Array.from({ length: 7 }, (_, day) => {
      const currentDay = addDays(sunday, day + (week - 1) * 7);
      const dayData = weekData[day];

      if (dayData.type === 'unavailable') {
        return {
          day,
          datetime: formatLocalDateTime(currentDay),
          start_datetime: formatLocalDateTime(currentDay),
          end_datetime: formatLocalDateTime(currentDay),
          type: 'full_day_off',
          week: parseInt(week, 10),
          preferences: dayData.preferences,
        };
      }
      if (dayData.type === 'available') {
        if (isFullDay(dayData)) {
          const startDateTime = new Date(
            currentDay.getFullYear(),
            currentDay.getMonth(),
            currentDay.getDate(),
            dayData.time_ranges[0].start_hour,
            dayData.time_ranges[0].start_minute
          );
          const endDateTime = new Date(
            currentDay.getFullYear(),
            currentDay.getMonth(),
            currentDay.getDate(),
            dayData.time_ranges[0].end_hour,
            dayData.time_ranges[0].end_minute
          );

          return {
            day,
            datetime: formatLocalDateTime(startDateTime),
            start_datetime: formatLocalDateTime(startDateTime),
            end_datetime: formatLocalDateTime(endDateTime),
            type: 'full_day_available',
            week: parseInt(week, 10),
            preferences: dayData.preferences,
            time_ranges: dayData.time_ranges,
          };
        }

        const startDateTime = new Date(
          currentDay.getFullYear(),
          currentDay.getMonth(),
          currentDay.getDate(),
          dayData.time_ranges[0].start_hour || 0,
          dayData.time_ranges[0].start_minute || 0
        );

        const endDateTime = new Date(
          currentDay.getFullYear(),
          currentDay.getMonth(),
          currentDay.getDate(),
          dayData.time_ranges[0].end_hour || 23,
          dayData.time_ranges[0].end_minute || 59
        );

        return {
          day,
          datetime: formatLocalDateTime(startDateTime),
          start_datetime: formatLocalDateTime(startDateTime),
          end_datetime: formatLocalDateTime(endDateTime),
          type: 'partially_available',
          week: parseInt(week, 10),
          preferences: dayData.preferences,
          time_ranges: dayData.time_ranges,
        };
      }
      return null;
    });
  });

  return formattedSchedule;
};

export const isSameDayOrWithinInterval = (
  unavailableTime,
  timezone,
  selectedDay
) => {
  const startDate = utcToZonedTime(
    new Date(unavailableTime.start_datetime),
    timezone
  );
  const endDateRaw = utcToZonedTime(
    new Date(unavailableTime.end_datetime),
    timezone
  );

  const endDate =
    endDateRaw.getHours() === 0 &&
    endDateRaw.getMinutes() === 0 &&
    endDateRaw.getSeconds() === 0
      ? subSeconds(startOfDay(endDateRaw), 1)
      : endDateRaw;

  const start = startOfDay(startDate);
  const end = endOfDay(endDate);

  if (start && end && start <= end) {
    return (
      isSameDay(selectedDay, startDate) ||
      isWithinInterval(selectedDay, {
        start: startDate,
        end: endDate,
      })
    );
  }
  return false;
};
