import React from 'react';
import Link from '@mui/material/Link';
import { Box } from '@mui/material';

function JobAddress({ name, address, details, selfIdentity }) {
  const fullAddress = [address, details].join(', ');

  return (
    <Link
      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        fullAddress
      )}`}
      target="_blank"
      rel="noopener noreferrer"
      sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}
      data-dd-action-name="Location Link"
    >
      <Box>
        <div>
          {name} {selfIdentity && ` (${selfIdentity})`}
        </div>
        <div>{address}</div>
        <div>{details}</div>
      </Box>
    </Link>
  );
}

export default JobAddress;
