/* eslint-disable camelcase */
import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';

import { format } from 'date-fns-tz';
import { formatTimeRange } from 'lib/utils';
import {
  formatChangedDate,
  getAvailabilityStatus,
} from 'components/AssociateWorkPreferences/utils';
import { ActivityEventInfo, ActivityEventStatus } from './style';

function AssociateDailyActivityEvent({ eventLog, associateTimeZone }) {
  const { palette } = useTheme();
  const {
    start_hour,
    start_minute,
    end_hour,
    end_minute,
    actual_start_minute,
    actual_start_hour,
    actual_end_hour,
    actual_end_minute,
    actual_effective_date,
    actual_day,
  } = eventLog.daily_availability;
  const { operation, created_at } = eventLog;

  return (
    <Box id="daily-activity-event">
      <ActivityEventInfo>
        <ActivityEventStatus>
          <Typography variant="body2" color={palette.text.secondary}>
            {formatChangedDate(created_at, associateTimeZone)}
          </Typography>
          <Typography
            id="availability-change"
            variant="body2"
            color={palette.text.secondary}
          >
            {getAvailabilityStatus(actual_effective_date, associateTimeZone)}
          </Typography>
        </ActivityEventStatus>
      </ActivityEventInfo>
      <Typography
        variant="subtitle3"
        color={palette.text.primary}
        id="changed-day"
      >
        {format(new Date(0, 0, actual_day + 1), 'EEEE')} hours
      </Typography>
      <Box css={{ display: 'flex' }}>
        {operation === 'INSERT' && (
          <Typography id="daily-insert" variant="body1">
            {formatTimeRange({
              start_hour: start_hour.new,
              start_minute: start_minute.new,
              end_hour: end_hour.new,
              end_minute: end_minute.new,
            })}
          </Typography>
        )}
        {operation === 'UPDATE' && (
          <Box css={{ display: 'flex' }} id="daily-update">
            <Typography variant="body1">
              New:{' '}
              {formatTimeRange({
                start_hour: start_hour ? start_hour.new : actual_start_hour,
                start_minute: start_minute
                  ? start_minute.new
                  : actual_start_minute,
                end_hour: end_hour ? end_hour.new : actual_end_hour,
                end_minute: end_minute ? end_minute.new : actual_end_minute,
              })}
            </Typography>
            <Divider
              orientation="vertical"
              css={{ margin: '0 8px', color: palette.divider }}
            />
            <Typography variant="body1" color={palette.text.secondary}>
              Old:{' '}
              {formatTimeRange({
                start_hour: start_hour ? start_hour.old : actual_start_hour,
                start_minute: start_minute
                  ? start_minute.old
                  : actual_start_minute,
                end_hour: end_hour ? end_hour.old : actual_end_hour,
                end_minute: end_minute ? end_minute.old : actual_end_minute,
              })}
            </Typography>
          </Box>
        )}
        {operation === 'DELETE' && (
          <Box
            id="daily-delete"
            css={{ display: 'flex', alignItems: 'center' }}
          >
            <CancelIcon color="error" css={{ width: '16px', height: '16px' }} />
            <Typography
              variant="subtitle3"
              css={{ color: '#D32F2F', marginLeft: '4px' }}
            >
              Removed
            </Typography>

            <Divider
              orientation="vertical"
              css={{ margin: '0 8px', color: palette.divider }}
            />
            <Typography variant="body1" color={palette.text.secondary}>
              Old:{' '}
              {formatTimeRange({
                start_hour: actual_start_hour,
                start_minute: actual_start_minute,
                end_hour: actual_end_hour,
                end_minute: actual_end_minute,
              })}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default AssociateDailyActivityEvent;
