import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AssociateDrawer from '../../AssociateDrawer/AssociateDrawer';
import { NO_ASSOCIATE } from '../../EditJobDrawer/constants';
import JobDetailsDrawer from '../../JobDetailsDrawer/JobDetailsDrawer';
import EditJobDrawer from '../../EditJobDrawer/EditJobDrawer';

function Drawers({ gridApiRef }) {
  const [selectedAssociate, setSelectedAssociate] = useState(NO_ASSOCIATE);
  const selectedEditField = useSelector(
    (state) => state.staffingWorkPage.editJob.selectedEditField
  );
  return (
    <>
      <JobDetailsDrawer />
      <EditJobDrawer
        selectedAssociate={selectedAssociate}
        setSelectedAssociate={setSelectedAssociate}
        isOpen={!!selectedEditField}
        editField={selectedEditField}
        gridApiRef={gridApiRef}
      />
      <AssociateDrawer
        setSelectedAssociate={setSelectedAssociate}
        gridApiRef={gridApiRef}
      />
    </>
  );
}

export default Drawers;
