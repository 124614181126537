import { Box, Button, Typography, Badge } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers-pro';
import { isToday } from 'date-fns';
import styled from '@emotion/styled/macro';
import { primaryText } from '../../styles/theme/colors';
import { defaultColor, theme } from '../../styles/theme';

export const HeaderWrapper = styled.header`
  position: relative;
  color: ${primaryText};
  background-color: ${defaultColor};
  overflow: hidden;
  padding: 8px 16px;

  &::after {
    content: ' ';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 70px;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }

  *:not(div) {
    /* In order for the blurred map overlay to not go over everything else */
    z-index: 10;
    position: relative;
  }

  .close-drawer {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 24px;
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    line-height: 0;
    cursor: pointer;
    z-index: 20;
    transition: all 0.1s ease-in-out;

    i {
      color: ${primaryText};
    }

    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    }
  }
`;

export const BlackSquare = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #061a2ade;
  margin-bottom: 2px;
`;

export const ContactActionButtonsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const ContactActionButton = styled(Button)`
  font-size: 13px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: 0.46px;
  text-decoration: none;
`;

export const ContentWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
`;

export const TooltipMessage = styled(Typography)`
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  text-decoration: none;
  color: white;
`;

export const HighlightedDay = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;
export const StyledPickersDay = styled(PickersDay)(({
  isPartiallyAvailableDay,
  isFullDayOff,
  isSelectedDay,
  day,
}) => {
  const textPrimaryColor = theme.palette.text.primary;
  const partialAvailableDayStyle = {
    backgroundImage:
      'repeating-linear-gradient(135deg, #E2E7EC, #E2E7EC 6px, transparent 5px, transparent 10px)',
    backgroundSize: '100%',
    borderRadius: '50%',
    border: `1px solid ${isSelectedDay ? theme.palette.primary.main : '#E2E7EC'}`,
  };

  const fullDayOffStyle = {
    backgroundColor: '#E1E3E5',
    color: '#99A1A8',
  };

  const selectedDayHoverFocusStyle = {
    '&:hover': {
      ...partialAvailableDayStyle,
      backgroundColor: 'transparent',
      color: textPrimaryColor,
    },
    '&:focus': {
      ...partialAvailableDayStyle,
      backgroundColor: 'transparent',
      color: textPrimaryColor,
    },
  };

  return {
    color: textPrimaryColor,
    ...(isPartiallyAvailableDay && !isSelectedDay && partialAvailableDayStyle),
    ...(isFullDayOff && !isSelectedDay && fullDayOffStyle),
    '&.Mui-selected': {
      ...(isPartiallyAvailableDay && {
        ...partialAvailableDayStyle,
        backgroundColor: 'transparent',
        color: textPrimaryColor,
        ...selectedDayHoverFocusStyle,
        border: `1px solid ${theme.palette.primary.main}`,
      }),
      ...(isSelectedDay &&
        !isPartiallyAvailableDay &&
        !isFullDayOff && {
          border: isToday(day) && '1px solid #CAC6CE',
          '&:hover': { backgroundColor: theme.palette.primary.main },
          '&:focus': { backgroundColor: theme.palette.primary.main },
        }),
      ...(isSelectedDay &&
        isFullDayOff && {
          ...fullDayOffStyle,
          opacity: '1',
          border: `1px solid ${theme.palette.primary.main}`,
          '&:hover': fullDayOffStyle,
          '&:focus': fullDayOffStyle,
        }),
    },
    '&:not(.Mui-selected)': {
      ...(isToday(day) && { border: '1px solid #E2E7EC' }),
    },
  };
});

export const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    bottom: -4,
    top: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    cursor: 'pointer',
  },
});

export const LegendItem = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CircleBox = styled(Box)(({ customStyles = {} }) => ({
  borderRadius: '50%',
  border: '1px solid #c6cace',
  width: '36px',
  height: '36px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...customStyles,
}));

export const DayBox = styled(Box)`
  width: 36px;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
