import React, { useState, useEffect } from 'react';
import SearchAssociate from 'components/JobTable/components/SearchAssociate';
import { SearchBoxContainer, AssociateChip, ChipsContainer } from '../style';
import { handleSelectOption } from '../utils';

function AssociateSearch({ setAssociateIds, setPage }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const filterValues = selectedOptions.map(({ id }) => id).join(', ');
    setAssociateIds(filterValues);
  }, [selectedOptions]);

  const handleRemoveOption = (associateId) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.filter(({ id }) => id !== associateId)
    );
  };

  return (
    <SearchBoxContainer>
      <SearchAssociate
        sx={{
          minWidth: '240px',
          backgroundColor: 'white',
        }}
        onChange={(option) => {
          setPage(0);
          handleSelectOption(option, setSelectedOptions);
        }}
        value={null}
        shouldStopDisable
        shouldClearOnChange
        id="associate-search"
      />
      <ChipsContainer>
        {selectedOptions.map((option) => (
          <AssociateChip
            key={option.id}
            id="associate-chip"
            label={option.label}
            variant="outlined"
            onDelete={() => handleRemoveOption(option.id)}
          />
        ))}
      </ChipsContainer>
    </SearchBoxContainer>
  );
}

export default AssociateSearch;
