import { Button, ButtonGroup } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FILTER_MODE_VARIANTS, FILTERS_WITH_MODES } from '../constants';
import { updateFilterModes } from '../../../state/ducks/filters/actions';
import { camelToKebab } from '../../../services/case';

function FilterModeButtonGroup({ sectionKey }) {
  if (FILTERS_WITH_MODES.includes(sectionKey)) {
    const filterMode = useSelector(
      (state) => state.filters.filterModes[sectionKey]
    );
    const dispatch = useDispatch();

    return (
      <ButtonGroup
        aria-label="Filter Mode Button Group"
        variant="outlined"
        size="small"
        css={{
          padding: '0 16px 4px 16px',
        }}
      >
        {FILTER_MODE_VARIANTS.map((variant) => (
          <Button
            id={`filter-item-${camelToKebab(sectionKey)}-${variant}-button`}
            onClick={() => {
              dispatch(updateFilterModes({ [sectionKey]: variant }));
            }}
            className={filterMode === variant ? 'selected' : undefined}
          >
            {variant}
          </Button>
        ))}
      </ButtonGroup>
    );
  }

  return null;
}

export default FilterModeButtonGroup;
