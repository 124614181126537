import React from 'react';
import { DateCalendar } from '@mui/x-date-pickers-pro';
import { Stack } from '@mui/material';
import { startOfMonth, endOfMonth } from 'date-fns';
import { defaultColor } from '../../../styles/theme';
import ServerDay from './ServerDay';
import CalendarLegend from './CalendarLegend';

function MonthAndWeekdaySelector({
  selectedDay,
  setSelectedDay,
  overallTimeUnavailable,
  setMonthRange,
  timezone,
}) {
  const handleDateChange = (date) => {
    setSelectedDay(date);
  };

  const handleMonthChange = (date) => {
    const start = startOfMonth(date);
    const end = endOfMonth(date);
    setMonthRange({ start, end });
    setSelectedDay(date);
  };

  return (
    <Stack
      css={{
        backgroundColor: defaultColor,
        borderTop: '1px solid #c6cace',
        height: 'fit-content',
      }}
    >
      <CalendarLegend />
      <DateCalendar
        onChange={handleDateChange}
        onMonthChange={handleMonthChange}
        value={selectedDay}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            selectedDay,
            overallTimeUnavailable,
            timezone,
            handleDateChange,
          },
        }}
        css={{
          width: '100%',
          height: 'auto',
        }}
      />
    </Stack>
  );
}

export default MonthAndWeekdaySelector;
