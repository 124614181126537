import React from 'react';
import { utcToZonedTime, format } from 'date-fns-tz';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Chip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { hovered } from 'styles/theme/colors';
import { standardDateFnsFormat } from 'services/date';
import { calculateTimeFrame, formatTimezone } from '../utils';

function AvailableSchedule({ associate, unavailableTime, selectedDay }) {
  const { palette } = useTheme();

  const formatZonedDateTime = (dateTime, dateFormat) => {
    const zonedTime = utcToZonedTime(
      new Date(dateTime),
      associate.profile.timezone
    );
    return format(zonedTime, dateFormat, {
      timeZone: associate.profile.timezone,
    });
  };

  return (
    <>
      {unavailableTime.isOvernight && (
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <ErrorOutlineOutlinedIcon
            color="error"
            css={{ width: '16px', height: '16px' }}
          />
          <Typography
            variant="subtitle4"
            css={{
              marginLeft: '4px',
              lineHeight: '100%',
            }}
            color="error"
          >
            Overnight Work
          </Typography>
        </Box>
      )}
      <Box
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '8px',
        }}
      >
        <Box>
          <Typography variant="subtitle2">
            {`${format(selectedDay, 'LLLL d, yyyy', {
              timeZone: associate.profile.timezone,
            })}`}
          </Typography>
          <Typography variant="subtitle2">
            {`${formatZonedDateTime(
              unavailableTime.job_start_after,
              'h:mm a'
            )} - ${formatZonedDateTime(
              unavailableTime.job_finish_before,
              'h:mm a'
            )} `}
            {formatTimezone(
              associate.profile.timezone,
              unavailableTime.job_start_after
            )}
          </Typography>
        </Box>
        <Chip
          size="small"
          variant="outlined"
          css={{
            backgroundColor: hovered,
            textColor: palette.text.primary,
          }}
          label={calculateTimeFrame(
            unavailableTime.job_finish_before,
            unavailableTime.job_start_after
          )}
        />
      </Box>

      <Typography variant="body2">
        {unavailableTime.external_project_identifier}
        {' - '}
        {unavailableTime.name} - {unavailableTime.banner_name} (
        {unavailableTime.store_location_self_identity})
      </Typography>
      <Typography variant="body2">
        {unavailableTime.store_location_address.address}
      </Typography>
      <Typography variant="body2">
        {`${unavailableTime.store_location_address.city}, ${unavailableTime.store_location_address.state} ${unavailableTime.store_location_address.postal_code}`}
      </Typography>
      <Box
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '8px',
        }}
      >
        <Typography variant="body2">
          {`Earliest Start: ${formatZonedDateTime(
            unavailableTime.created_job_start_after,
            standardDateFnsFormat
          )}`}
        </Typography>
        <Typography variant="body2">
          {`Latest Finish: ${formatZonedDateTime(
            unavailableTime.created_job_finish_before,
            standardDateFnsFormat
          )}`}
        </Typography>
      </Box>
    </>
  );
}

export default AvailableSchedule;
