import React, { useState } from 'react';

import Chip from '@mui/material/Chip';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import {
  AssignOrViewAssociateWrapper,
  AssociateInfo,
  AssociateInfoLabel,
  AssociateWrapper,
} from '../style';
import {
  determineChipColorAndLabel,
  determineDateDistance,
  formatRecentActivityDate,
} from '../utils';
import { determineMatchedAssociateAssignButtonText } from '../../../lib/utils';
import { camelToKebab } from '../../../services/case';

function Associate({
  associate,
  storeLocationId,
  onShowAssociateDetails,
  handleAssignAssociateClick,
}) {
  const [display, setDisplay] = useState('none');
  const isHomeStore = storeLocationId === associate.home_store_location_id;
  const batchJobs = useSelector(
    (state) => state.staffingWorkPage.editJob.batchJobs
  );
  const {
    palette: {
      text: { primary, secondary },
      backgroundColor,
    },
    typography: { subtitle4 },
  } = useTheme();

  return (
    <AssociateWrapper
      className="matched-associate"
      onMouseEnter={() => {
        setDisplay('flex');
      }}
      onMouseLeave={() => {
        setDisplay('none');
      }}
    >
      <Box
        css={{
          padding: '16px',
        }}
      >
        <Stack
          direction="row"
          css={{
            flexWrap: 'wrap',
            alignItems: 'center',
            marginBottom: '4px',
            marginTop: '4px',
          }}
        >
          {associate.exceptions.length === 0 ? (
            <Chip
              css={{ marginRight: '4px' }}
              label="Match"
              size="small"
              color="success"
            />
          ) : (
            associate.exceptions.map((exception) => {
              const { color, label } = determineChipColorAndLabel(exception);
              return (
                <Chip
                  id={`${camelToKebab(label)}-chip`}
                  color={color}
                  key={exception}
                  label={label}
                  size="small"
                  css={{
                    marginRight: '4px',
                    marginTop: '4px',
                    marginBottom: '4px',
                  }}
                />
              );
            })
          )}
          {isHomeStore && (
            <Chip
              id="home-store-chip"
              label="Home Store"
              color="secondary"
              size="small"
              css={{
                marginRight: '4px',
                marginTop: '4px',
                marginBottom: '4px',
              }}
            />
          )}
        </Stack>
        <Typography variant="subtitle3">{associate.full_name}</Typography>
        <Typography
          id="associate-id"
          variant="body2"
          css={{
            color: secondary,
            lineHeight: '110%',
          }}
        >
          {associate.external_id} - {associate.title}
        </Typography>
        <Typography variant="body2" id="associate-division">
          {associate.division_name}
        </Typography>
        {associate.hierarchies.map(({ name }) => (
          <Chip
            key={name}
            label={name}
            css={{
              height: '25px',
              color: primary,
              backgroundColor: backgroundColor.primary,
              ...subtitle4,
              margin: '8px 0',
              '& .MuiChip-label': {
                padding: '0 8px',
              },
              border: '1px solid #c6cace',
              borderRadius: '4px',
            }}
          />
        ))}
        <Box
          css={{
            display: 'flex',
            width: '100%',
            marginTop: '8px',
          }}
        >
          <Box css={{ width: '110px' }} id="associate-miles-from-home">
            <AssociateInfo variant="body2">
              {associate.miles_from_job} mi radius
            </AssociateInfo>
            <AssociateInfoLabel color={secondary}>
              {Math.round(associate.estimated_driving_miles)}+ MI DRIVING
            </AssociateInfoLabel>
          </Box>
          <Divider css={{ borderWidth: '1px', margin: '0 8px' }} />
          <Box css={{ width: '85px' }} id="associate-remaining-hours">
            <AssociateInfo variant="body2">
              {associate.remaining_hours} hours
            </AssociateInfo>
            <AssociateInfoLabel color={secondary}>REMAINING</AssociateInfoLabel>
          </Box>
          <Divider css={{ borderWidth: '1px', margin: '0 8px' }} />
          <Box id="associate-remaining-hours">
            <Box css={{ display: 'flex' }}>
              <AssociateInfo variant="body2">
                {`${determineDateDistance(associate.last_active_date)}\u00A0`}
              </AssociateInfo>
              <AssociateInfo
                variant="body2"
                color={secondary}
                css={{ fontWeight: '400' }}
              >
                {formatRecentActivityDate(associate.last_active_date)}
              </AssociateInfo>
            </Box>
            <AssociateInfoLabel color={secondary}>
              RECENT ACTIVITY
            </AssociateInfoLabel>
          </Box>
        </Box>
      </Box>
      <AssignOrViewAssociateWrapper display={display}>
        <Button
          variant="contained"
          className="matched-associate-view-button"
          onClick={() => {
            onShowAssociateDetails({
              hideMatchDetails: false,
              hideAssignButton: false,
              ...associate,
            });
          }}
        >
          View
        </Button>
        <Button
          variant="contained"
          className="matched-associate-assign-button"
          onClick={() =>
            handleAssignAssociateClick(associate.user_id, associate.full_name)
          }
          css={{
            marginLeft: '16px',
          }}
        >
          {determineMatchedAssociateAssignButtonText(batchJobs)}
        </Button>
      </AssignOrViewAssociateWrapper>
      <Divider />
    </AssociateWrapper>
  );
}

export default Associate;
