import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import { isOtherReasonErrored } from '../utils';
import { getReassignmentReasons } from '../../../services/batch';

const other = 'Other';

function ReassignmentReason({
  selectedReassignmentReason,
  setSelectedReassignmentReason,
  otherReason,
  setOtherReason,
}) {
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(false);

  const getReasons = () => {
    setIsLoading(true);
    setApiError(false);
    getReassignmentReasons()
      .then(({ results }) => {
        const fetchedOptions = results.map((result) => ({
          label: result.reason,
          value: result.id,
          id: result.id,
        }));
        setOptions(fetchedOptions);
        setIsLoading(false);
      })
      .catch(() => {
        setApiError(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getReasons();
  }, []);

  const handleOtherReasonChange = (value) => {
    setOtherReason(value);
    setError(isOtherReasonErrored(value));
  };

  if (isLoading) {
    return <Skeleton variant="rounded" width="100%" height="60px" />;
  }
  return (
    <Box css={{ marginTop: '24px' }}>
      <Typography variant="body2" css={{ marginBottom: '16px' }}>
        We&apos;d like to know why you&apos;re editing this assignment so we can
        improve the AI Assistant. Please select a reason before saving this
        change.
      </Typography>
      {apiError ? (
        <Box
          css={{
            marginTop: '24px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="subtitle1"
            color="error"
            id="reassignment-reasons-error"
          >
            There was an error retrieving reassignment reasons.
          </Typography>
          <Button
            variant="outlined"
            className="button"
            css={{ marginTop: '8px' }}
            onClick={getReasons}
            id="reassignment-reasons-refresh-button"
          >
            Refresh
          </Button>
        </Box>
      ) : (
        <FormControl fullWidth>
          <InputLabel id="reassignment-label">Reason For Edit</InputLabel>
          <Select
            variant="outlined"
            labelId="reassignment-label"
            id="reassignment-select"
            label="Reason For Edit"
            value={selectedReassignmentReason.value || ''}
            onChange={(event) => {
              const selectedOption = options.find(
                (option) => option.value === event.target.value
              );
              if (selectedOption.label === other && otherReason !== '') {
                setOtherReason('');
              }
              setSelectedReassignmentReason(selectedOption);
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Required</FormHelperText>
          {selectedReassignmentReason &&
            selectedReassignmentReason.label === other && (
              <>
                <TextField
                  id="other-reason-text-field"
                  label="Other Reason"
                  variant="outlined"
                  css={{ marginTop: '24px' }}
                  value={otherReason}
                  error={error}
                  onChange={(event) =>
                    handleOtherReasonChange(event.target.value)
                  }
                />
                <FormHelperText>Required (4-40 characters)</FormHelperText>
              </>
            )}
        </FormControl>
      )}
    </Box>
  );
}

export default ReassignmentReason;
